// Bootstrap
@import "node_modules/bootstrap/dist/css/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox";

html {
  font-size: 13px
}

.bg-gradient {
  background: rgb(255, 255, 255); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(245, 242, 242, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(245, 242, 242, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(245, 242, 242, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f2f2', GradientType=0); /* IE6-9 */
}

.no-padding {
  padding: 0;
}

.box-shadow {
  box-shadow: 1px 0 3px 1px rgba(0, 0, 0, 0.51);
}

$border-color: #bcbbbc;
@each $position in ('top', 'left', 'right', 'bottom') {
  .border-#{$position}-gray {
    border-#{$position}: 1px solid $border-color;
  }
}

.navbar {
  line-height: 1.5rem;

  .navbar-brand {
    color: #383737;
  }
}


/* Middle column -> mails list */
.mails-list {
  overflow-x: hidden;
  overflow-y: scroll;

  .filter {
    background-color: #f0f0f0;
    height: 25px;
    font-weight: bold;
    line-height: 25px;
    font-size: 11px;
    padding: 0 15px;
    text-align: right;
  }

  .list-group-item {
    border-left: 0;
    border-right: 0;

    &:first-child {
      border-top: 0;
      border-radius: 0;
    }

    &:last-child {
      border-radius: 0;
    }

    p {
      font-size: 0.9rem;
    }

    &.list-group-item-action {
      &:active, &.active {
        color: white;
        background-color: #005ed4;
      }
    }
  }
}

.mail-detail {
  .mail-holder {
    padding-top: 1rem;

    iframe {
      flex: 1;
      border: 0;
    }
  }

  .mail-actions {
    overflow: hidden;
    padding: 0 1rem;
    background-color: #fbfbfb;

    .logout-box {
      border-left: 1px solid rgba(0, 0, 0, 0.1);

      .nav {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .nav-item {
          padding: 0 1em 0.5em 0;

          .nav-link {
            padding: 0.1em 0.5em;
            border-radius: 5px;
            color: #4d4b4b;

            &:hover {
              color: #fffef5;
              background-color: #aeadae;
            }

            &.active {
              color: #fffef5;
              background-color: #878687;
            }
          }
        }
      }

      .tab-content {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 120px;

        ul {
          padding: 0;
          margin: 0.3rem 0 0;

          li {
            line-height: 20px;
          }
        }
      }
    }

  }
}

/* Sidebar navigation */
.sidebar {
  background-color: #f5f2f2;
  padding-left: 0;
  padding-right: 0;

  .nav {
    margin-bottom: 20px;
  }

  .nav-item {
    width: 100%;

    + .nav-item {
      margin-left: 0;
    }
  }

  .nav-link {
    border-radius: 0;
    color: #383737;
    cursor: pointer;

    &.active {
      color: #383737;
      background-color: #d3d3d6;
    }

    &:hover {
      background-color: rgba(211, 211, 214, 0.6);
    }
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #aaa;
      margin: -4px 0 0 -4px;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;

      &:after {
        top: 63px;
        left: 63px;
      }
    }

    &:nth-child(2) {
      animation-delay: -0.072s;

      &:after {
        top: 68px;
        left: 56px;
      }
    }

    &:nth-child(3) {
      animation-delay: -0.108s;

      &:after {
        top: 71px;
        left: 48px;
      }
    }

    &:nth-child(4) {
      animation-delay: -0.144s;

      &:after {
        top: 72px;
        left: 40px;
      }
    }

    &:nth-child(5) {
      animation-delay: -0.18s;

      &:after {
        top: 71px;
        left: 32px;
      }
    }

    &:nth-child(6) {
      animation-delay: -0.216s;

      &:after {
        top: 68px;
        left: 24px;
      }
    }

    &:nth-child(7) {
      animation-delay: -0.252s;

      &:after {
        top: 63px;
        left: 17px;
      }
    }

    &:nth-child(8) {
      animation-delay: -0.288s;

      &:after {
        top: 56px;
        left: 12px;
      }
    }
  }
}


@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}